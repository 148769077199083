<template>
  <div class="container">
    <div class="top">
      <p class="title" @click="tohome">全国建筑企业资质查询系统</p>
    </div>
    <div class="search-page">
      <el-tabs v-model="activeName">
        <el-tab-pane label="企业资质资格" name="1">
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>企业名称</th>
                <th>资质证书号</th>
                <th>资质名称</th>
                <th>证书有效期</th>
                <th>发证机关</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in enterpriseData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.enterpriseName }}</td>
                <td>{{ item.certificateNo }}</td>
                <td>{{ item.creditName }} {{ item.rating }}</td>
                <td>{{ item.effectiveDate }}</td>
                <td>{{ item.organName }}</td>
              </tr>
              <tr v-if="enterpriseData.length === 0">
                <td colspan="8">暂时没有查询到您的证书信息</td>
              </tr>
            </tbody>
          </table>
        </el-tab-pane>
        <el-tab-pane label="从业人员资格" name="2">
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>姓名</th>
                <th>注册类别</th>
                <th>注册号（执业印章号）</th>
                <th>发证机关</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in personData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.qualificationName }}</td>
                <td>{{ item.certificateNo }}</td>
                <td>{{ item.organName}}</td>
              </tr>
              <tr v-if="personData.length === 0">
                <td colspan="8">暂时没有查询到您的证书信息</td>
              </tr>
            </tbody>
          </table>
        </el-tab-pane>
      </el-tabs>
      <div class="footer">
        <p>&copy;{{ time }} &ensp; <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备17042384号-2</a></p>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
      activeName: "1",
      enterpriseData: [],
      personData: [],
      time: "",
    };
  },
  methods: {
    getdata() {
      axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "/query/jzcx?EnterpriseName=" + this.$route.query.search,
      }).then((response) => {
        if (response.data.status === 0) {
          this.enterpriseData = response.data.data.enterpriseData;
          this.personData = response.data.data.personData;
        }
      });
    },
    tohome() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getdata();
    var date = new Date();
    this.time = date.getFullYear();
  }
};
</script>

<style lang="scss" scoped>
.container {
  color: #2c3e50;
  height: 100%;
  min-width: 1200px;
  max-width: 1440px;
  margin: 0 auto;
}
.top {
  padding: 0 220px;
  height: 60px;
  .title {
    color: #d82d2c;
    font-size: 30px;
    letter-spacing: 4px;
    line-height: 60px;
    cursor: pointer;
    float: left;
  }
}

.search-page {
  width: 100%;
  background: #f7f7f7;
  overflow: hidden;
  border-top: 1px solid #d82d2c;
  padding-bottom: 30px;
  .el-tabs {
    width: 1000px;
    margin: 30px auto;
  }
  /deep/.el-tabs__item {
    font-size: 16px;
  }
  /deep/.el-tabs__header {
    margin: 0;
    background: #fff;
    border: 1px solid #e9e9e9;
    padding: 0 10px;
    border-bottom: none;
  }
  /deep/.el-tabs__item:hover,
  /deep/.el-tabs__item.is-active {
    color: #d82d2c;
	background: url("../assets/images/selected.png") no-repeat 45% 100%;
  }
  /deep/.el-tabs__active-bar {
     background-color: #d82d2c;
  }
}
table {
  border: 1px solid #e9e9e9;
  border-collapse: collapse;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #666;
}
table td,
table th {
  border: 1px solid #e9e9e9;
  padding: 5px;
  height: 54px;
  border-bottom-width: 2px;
  text-align: center;
}
.result table tr {
  background-color: #f7f7f7;
}
table tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
.footer {
  border-top: 2px solid #d82d2c;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
}
</style>